import React from "react"
import withFieldset from "./with-fieldset";
import InputWrapper from "../../elements/input-wrapper";

class Finances extends React.Component {

    renderOptions(amount) {
        var options = [];
        for (let i = 1; i <= amount; i++) {
            options.push(<option>{i}</option>);
        }
        return options;
    }

    render() {
        return (
            <div className="section-container">
                <div className="section section-form">
                    <div className="section-form-title">
                        <h4>Your finances</h4>
                        <p className="lead">Please enter accurate information. It will improve your chances of being
                            approved.</p>
                    </div>
                    <div className="form-container">
                        <div className="row row-equal">
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('monthly_income')}>
                                    <label>Net monthly income</label>
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fas fa-fw fa-pound-sign"></i>
                                        </div>
                                        <input ref={this.props.createInputRef.bind(null, 'monthly_income')} type="text"
                                               className="form-control numbers-only"/>
                                    </div>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('rent_mortgage')}>
                                    <label>Monthly rental/mortgage payments</label>
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fas fa-fw fa-pound-sign"></i>
                                        </div>
                                        <input ref={this.props.createInputRef.bind(null, 'rent_mortgage')} type="text"
                                               className="form-control numbers-only"/>
                                    </div>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('loans')}>
                                    <label>Monthly loan payments</label>
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fas fa-fw fa-pound-sign"></i>
                                        </div>
                                        <input ref={this.props.createInputRef.bind(null, 'loans')} type="text"
                                               className="form-control numbers-only"/>
                                    </div>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('other')}>
                                    <label>Other expenses</label>
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            <i className="fas fa-fw fa-pound-sign"></i>
                                        </div>
                                        <input ref={this.props.createInputRef.bind(null, 'other')} type="text"
                                               className="form-control numbers-only"/>
                                    </div>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('bank_name')}>
                                    <label>Who do you bank with?</label>
                                    <select ref={this.props.createInputRef.bind(null, 'bank_name')}
                                            className="form-control">
                                        <option value="">Please select</option>
                                        <option>Bank of Scotland</option>
                                        <option>Barclays</option>
                                        <option>Clydesdale bank</option>
                                        <option>Halifax</option>
                                        <option>HSBC</option>
                                        <option>Lloyds Bank</option>
                                        <option>Metro Bank</option>
                                        <option>Monzo Bank</option>
                                        <option>National Westminster Bank</option>
                                        <option>Nationwide Building Society</option>
                                        <option>Prepay Technologies Ltd</option>
                                        <option>Santander</option>
                                        <option>Starling Bank</option>
                                        <option>The Co-Operative Bank</option>
                                        <option>The Royal Bank Of Scotland</option>
                                        <option>Think Money Limited</option>
                                        <option>TSB Bank</option>
                                        <option>Ulster Bank</option>
                                        <option>Other</option>
                                    </select>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('bank_duration')}>
                                    <label>Years at bank</label>
                                    <select ref={this.props.createInputRef.bind(null, 'bank_duration')}
                                            className="form-control">
                                        <option value="">Years</option>
                                        {this.renderOptions(80)}
                                    </select>
                                </InputWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const FinancesFieldset = withFieldset(Finances)

export default FinancesFieldset

