import React from "react"
import getValue from "../../helpers/getValue";

const withFieldset = WrappedFieldset => {

    return class Fieldset extends React.Component {

        fields = {}

        constructor(props) {
            super(props);
        }

        createInputRef(name, input) {
            this.fields[name] = input
        }

        getField(name) {
            return this.fields[name]
        }

        getValue(field) {
            return getValue(field)
        }

        getErrors(name) {
            if (this.props.errors && this.props.name in this.props.errors) {
                if (name) {
                    return this.props.errors[this.props.name][name]
                }
                return this.props.errors[this.props.name] ?
                    this.props.errors[this.props.name] : {}
            }
        }

        getData() {
            let data = {}
            for (let name in this.fields) {
                let field = this.getField(name)
                if (!field) {
                    continue
                }
                data[name] = this.getValue(field)
            }
            return data
        }

        render() {
            return (
                <WrappedFieldset
                    {...this.props}
                    createInputRef={this.createInputRef.bind(this)}
                    getErrors={this.getErrors.bind(this)}
                    fields={this.fields}
                />
            )
        }
    }
}

export default withFieldset

