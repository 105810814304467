import React from "react"
import ReactDOM from "react-dom"
import {navigate} from "gatsby"
import {Redirect} from "@reach/router"
import qs from 'qs';
import LoanFieldset from "./fieldset/loan-fieldset";
import PersonalFieldset from "./fieldset/personal-fieldset"
import EmploymentFieldset from "./fieldset/employment-fieldset";
import FinancesFieldset from "./fieldset/finances-fieldset";
import AddressFieldset from "./fieldset/address-fieldset";
import MarketingFieldset from "./fieldset/marketing-fieldset";
import getValue from "../helpers/getValue";
import withFieldset from "./fieldset/with-fieldset";
import SubAddressFieldset from "./fieldset/sub-address-fieldset";
import InputWrapper from "../elements/input-wrapper";

class LoanForm extends React.Component {

    fieldsets = {}

    constructor(props) {
        super(props)
        this.state = {loading: false, terms: false, errors: {}}
    }

    createFieldsetRef(ref) {
        if (ref && ref.props.name) {
            this.fieldsets[ref.props.name] = ref;
        }
    }

    onTermsChange(e) {
        this.setState({terms: !!getValue(e.target)})
    }

    getData() {
        let data = {}
        data['loan_amount'] = this.props.loanData.loanAmount
        data['months'] = this.props.loanData.months
        data['referring_site_url'] = window.location.origin
        data['test'] = 1
        if (this.state.terms) {
            data['agreed_terms'] = !!this.state.terms
        }
        for (let name in this.fieldsets) {
            if (typeof this.fieldsets[name].getData != 'undedined') {
                data[name] = this.fieldsets[name].getData()
            }
        }
        return data;
    }

    findErrorFieldset(fieldset, errors) {
        for (let name in errors[fieldset.props.name]) {
            let field = fieldset.fields[name];
            if (field && field.constructor.name == 'Fieldset') {
                fieldset = this.findErrorFieldset(field, errors[fieldset.props.name])
            }
            if (fieldset) {
                break;
            }
        }
        return fieldset;
    }

    submitForm(e) {
        e.preventDefault()
        if (this.state.loading) {
            return
        }
        this.setState({loading: true})
        const data = this.getData()
        fetch("https://api.loaned.co.uk/lender/lead/post?field_map=loaned_gatsby", {
            method: "POST",
            body: qs.stringify(data),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            return response.json()
        }).then(response => {
            this.setState({loading: false, errors: response.error_fields || {}})
            if (response.success) {
                navigate('/results/' + response.lead_id)
            } else if (response.error_fields) {
                let scrolled = false;
                for (let fieldset in this.fieldsets) {
                    if (!response.error_fields[fieldset]) {
                        continue
                    }
                    if (!scrolled) {
                        const errorFieldset = this.findErrorFieldset(
                            this.fieldsets[fieldset],
                            response.error_fields
                        );
                        const element = ReactDOM.findDOMNode(errorFieldset)
                        if (!element) {
                            continue
                        }
                        const pos = element.getBoundingClientRect()
                        window.scrollTo({
                            top: (pos.top + window.scrollY),
                            behavior: 'smooth'
                        });
                        scrolled = true;
                    }
                }
            }
        })
    }

    render() {
        if (!this.props.loanData) {
            return <Redirect to="/search/" noThrow/>
        }
        return (
            <div class="section-container">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-12">
                            <form onSubmit={this.submitForm.bind(this)}>
                                <LoanFieldset
                                    name='loan'
                                    ref={this.createFieldsetRef.bind(this)}
                                    errors={this.state.errors}
                                    {...this.props.loanData}
                                />
                                <PersonalFieldset
                                    name='personal'
                                    ref={this.createFieldsetRef.bind(this)}
                                    errors={this.state.errors}
                                />
                                <AddressFieldset
                                    name='addresses'
                                    ref={this.createFieldsetRef.bind(this)}
                                    errors={this.state.errors}
                                />
                                <EmploymentFieldset
                                    name='employment'
                                    ref={this.createFieldsetRef.bind(this)}
                                    errors={this.state.errors}
                                />
                                <FinancesFieldset
                                    name='finances'
                                    ref={this.createFieldsetRef.bind(this)}
                                    errors={this.state.errors}
                                />
                                <MarketingFieldset
                                    name='marketing'
                                    ref={this.createFieldsetRef.bind(this)}
                                    errors={this.state.errors}
                                />
                                <div className="section-container">
                                    <div className="section-form">
                                        <hr/>
                                        <div className="terms-check">
                                            <InputWrapper ref={this.createFieldsetRef.bind(this)} name="agreed_terms"
                                                          error={this.state.errors['agreed_terms']}>
                                                <input id="terms-check" onChange={this.onTermsChange.bind(this)}
                                                       type="checkbox"/>
                                                <label htmlFor="terms-check">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                                                    varius
                                                    metus quis fringilla efficitur. Aliquam quis massa a turpis placerat
                                                    facilisis. Pellentesque eu ullamcorper magna. Donec dolor justo,
                                                    cursus
                                                    eget elit eu,e potenti. Aenean a feugiat sapien. Vivamus vestibulum
                                                    sem
                                                    at nibh fringilla blandit.
                                                </label>
                                            </InputWrapper>
                                        </div>
                                        <div className="section-footer">
                                            <a onClick={this.submitForm.bind(this)} href="" className="btn btn-default">Show
                                                loan offers</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoanForm