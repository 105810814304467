import React from "react"
import Layout from "../layouts/index"
import SEO from "../components/seo"
import LoanForm from "../components/forms/loan-form";
import withLoanData from "../components/helpers/withLoanData";

class Apply extends React.Component {

    render() {
        const loanData = this.props.getLoanData()
        return (
            <Layout footer="min" location={this.props.location} reRender={true}>
                <SEO title="Search for a Loan" bodyAttributes={{class: 'quote-page'}}/>
                <LoanForm loanData={loanData} />
            </Layout>
        )
    }
}

const ApplyPage = withLoanData(Apply)

export default ApplyPage