import React from "react"
import qs from 'qs';
import withFieldset from "./with-fieldset";
import classNames from "classnames";
import InputWrapper from "../../elements/input-wrapper";
import getValue from "../../helpers/getValue";

class SubAddress extends React.Component {

    key = 'NC39-EC97-PZ78-MN82'

    constructor(props) {
        super(props)
        this.state = {addresses: {}, selectedAddress: {}, addressVisible: false}
    }

    findAddresses(e) {
        e.preventDefault()
        const postcode = getValue(this.props.fields['postcode'])
        this.setState({addresses: {}, selectedAddress: {}})
        fetch("https://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/Find/v1.10/json3.ws", {
            method: "POST",
            body: qs.stringify({
                Key: this.key,
                SearchTerm: postcode,
            }),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            return response.json()
        }).then(response => {
            if (typeof (response.Items[0].Error) == "undefined") {
                this.setState({addresses: response.Items})
            }
        })
    }

    selectAddress(e) {
        const id = e.target.value
        this.setState({selectedAddress: {}})
        if (id) {
            fetch("https://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/RetrieveById/v1.30/json3.ws", {
                method: "POST",
                body: qs.stringify({
                    Key: this.key,
                    Id: id,
                }),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(response => {
                return response.json()
            }).then(response => {
                if (response.Items.length) {
                    const address = response.Items[0]
                    this.setState({
                        selectedAddress: this.parseAddress(address),
                        addressVisible: true
                    })
                }
            })
        }
    }

    parseAddress(address) {
        return {
            name_or_number: address.BuildingName ? address.BuildingName : address.BuildingNumber,
            street: address.PrimaryStreet,
            city: address.PostTown,
            county: address.County,
            postcode: address.Postcode
        }
    }

    formatAddress(address) {
        return address.StreetAddress + ', ' + address.Place
    }

    showAddress(e) {
        e.preventDefault()
        this.setState({addressVisible: true})
    }

    render() {

        return (
            <div className="section-container">
                <div className="section section-form">
                    <div className="section-form-title">
                        <h4>{this.props.title}</h4>
                        <p className="lead" dangerouslySetInnerHTML={{__html: this.props.subtitle}} />
                    </div>
                    <div className="form-container">
                        <div className="row row-equal">
                            <div className="col col-xs-12 col-md-6">
                                <InputWrapper error={this.props.getErrors('postcode')}>
                                    <label>Postcode</label>
                                    <div className="form-postcode">
                                        <input ref={this.props.createInputRef.bind(null, 'postcode')} type="text"
                                               defaultValue={this.state.selectedAddress.postcode}
                                               className="form-control"/>
                                        <a href="" onClick={this.findAddresses.bind(this)}
                                           className="btn btn-sm btn-primary">Find
                                            address</a>
                                    </div>
                                    {!this.state.addressVisible && (
                                        <a href="" onClick={this.showAddress.bind(this)}>Enter address manually.</a>
                                    )}
                                </InputWrapper>
                            </div>
                            {this.state.addresses.length > 0 && (
                                <div className="col col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label>Select address</label>
                                        <select onChange={this.selectAddress.bind(this)} name=""
                                                className="form-control">
                                            <option value=''>Please select</option>
                                            {this.state.addresses.map(address => (
                                                <option key={address.Id}
                                                        value={address.Id}>{this.formatAddress(address)}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="col col-xs-12">
                                <div className="gap" style={{height: "50px;"}}></div>
                            </div>
                            <div className={classNames("col col-xs-12 col-md-6", {hidden: !this.state.addressVisible})}>
                                <InputWrapper error={this.props.getErrors('name_or_number')}>
                                    <label>House name / number</label>
                                    <input ref={this.props.createInputRef.bind(null, 'name_or_number')} type="text"
                                           defaultValue={this.state.selectedAddress.name_or_number}
                                           className="form-control"/>
                                </InputWrapper>
                            </div>
                            <div className={classNames("col col-xs-12 col-md-6", {hidden: !this.state.addressVisible})}>
                                <InputWrapper error={this.props.getErrors('street')}>
                                    <label>Street</label>
                                    <input ref={this.props.createInputRef.bind(null, 'street')} type="text"
                                           defaultValue={this.state.selectedAddress.street}
                                           className="form-control"/>
                                </InputWrapper>
                            </div>
                            <div className={classNames("col col-xs-12 col-md-6", {hidden: !this.state.addressVisible})}>
                                <InputWrapper error={this.props.getErrors('city')}>
                                    <label>City / town</label>
                                    <input ref={this.props.createInputRef.bind(null, 'city')} type="text"
                                           defaultValue={this.state.selectedAddress.city}
                                           className="form-control"/>
                                </InputWrapper>
                            </div>
                            <div className={classNames("col col-xs-12 col-md-6", {hidden: !this.state.addressVisible})}>
                                <InputWrapper error={this.props.getErrors('county')}>
                                    <label>County</label>
                                    <input ref={this.props.createInputRef.bind(null, 'county')} type="text"
                                           defaultValue={this.state.selectedAddress.county}
                                           className="form-control"/>
                                </InputWrapper>
                            </div>

                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper containerClasses={"field"}
                                              error={this.props.getErrors('residential_status')}>
                                    <label>Type of residence</label>
                                    <select ref={this.props.createInputRef.bind(null, 'residential_status')}
                                            className="form-control">
                                        <option value="">Please select</option>
                                        <option>Home Owner - With mortgage</option>
                                        <option>Home Owner - No mortgage</option>
                                        <option>Rent</option>
                                        <option>Living With Family</option>
                                        <option>Other</option>
                                        <option>Joint owner</option>
                                        <option>Living with Parents</option>
                                        <option>Tenant - Furnished</option>
                                        <option>Tenant - Unfurnished</option>
                                        <option>Council Tenant</option>
                                    </select>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <div className="form-group">
                                    <label>Time at this address</label>
                                    <div className="form-choice">
                                        <InputWrapper containerClasses={"field"}
                                                      error={this.props.getErrors('time_at_address_years')}>
                                            <select ref={this.props.createInputRef.bind(null, 'time_at_address_years')} onChange={this.props.onDurationChange}
                                                    className="form-control">
                                                <option value="">Years</option>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                            </select>
                                        </InputWrapper>
                                        <InputWrapper containerClasses={"field"}
                                                      error={this.props.getErrors('time_at_address_months')}>
                                            <select ref={this.props.createInputRef.bind(null, 'time_at_address_months')} onChange={this.props.onDurationChange}
                                                    className="form-control">
                                                <option value="">Months</option>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                                <option>11</option>
                                            </select>
                                        </InputWrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const SubAddressFieldset = withFieldset(SubAddress)

export default SubAddressFieldset

