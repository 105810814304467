import React from "react"
import withFieldset from "./with-fieldset";
import InputWrapper from "../../elements/input-wrapper";
import RadioInput from "../element/radio-input";

class Marketing extends React.Component {

    render() {
        return (
            <div className="section-container">
                <div className="section section-form">
                    <div className="section-form-title">
                        <h4>Marketing and confirmation</h4>
                        <p className="lead">Money would like to keep you informed of their latest products and
                            services.</p>
                    </div>
                    <div className="form-container">
                        <div className="row row-equal">
                            <div className="col col-xs-12 col-sm-6 col-md-4">
                                <InputWrapper error={this.props.getErrors('email')}>
                                    <label>Email</label>
                                    <RadioInput ref={this.props.createInputRef.bind(null, 'email')} name="email"
                                                choices={[{value: '1', label: 'Yes'}, {value: '0', label: 'No'}]}/>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
                                <InputWrapper error={this.props.getErrors('sms')}>
                                    <label>SMS</label>
                                    <RadioInput ref={this.props.createInputRef.bind(null, 'sms')} name="sms"
                                                choices={[{value: '1', label: 'Yes'}, {value: '0', label: 'No'}]}/>
                                </InputWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const MarketingFieldset = withFieldset(Marketing)

export default MarketingFieldset
