import React from "react"
import getValue from "../../helpers/getValue";

class RadioInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {value: props.defaultValue}
    }

    setValue(e) {
        this.setState({value: getValue(e.target)})
    }

    getValue() {
        return this.state.value;
    }

    render() {
        return (
            <div className="form-choice">
                {this.props.choices && this.props.choices.map(choice => (
                    <div className="field field-choice">
                        <input onChange={this.setValue.bind(this)}
                               id={"field-choice-" + this.props.name + "-" + choice.value}
                               name={this.props.name} type="radio"
                               value={choice.value}/>
                        <label htmlFor={"field-choice-" + this.props.name + "-" + choice.value}
                               className="form-control transition">{choice.label}</label>
                    </div>
                ))}
            </div>
        )
    }
}

export default RadioInput
