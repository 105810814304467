import React from "react"
import classNames from "classnames";
import withFieldset from "./with-fieldset";
import InputWrapper from "../../elements/input-wrapper";

class Personal extends React.Component {

    render() {
        return (
            <div className="section-container">
                <div className="section section-form">
                    <div className="section-form-title">
                        <h4>Your personal details</h4>
                        <p className="lead">We take security very seriously. Your details will remain secure.</p>
                    </div>
                    <div className="form-container">
                        <div className="row row-equal">
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('firstname')}>
                                    <label>First name</label>
                                    <input ref={this.props.createInputRef.bind(null, 'firstname')} type="text"
                                           className='form-control'/>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('lastname')}>
                                    <label>Last name</label>
                                    <input ref={this.props.createInputRef.bind(null, 'lastname')} type="text"
                                           className='form-control'/>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('gender')}>
                                    <label>Gender</label>
                                    <select ref={this.props.createInputRef.bind(null, 'gender')}
                                            className="form-control">
                                        <option value="">Please select</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('marital_status')}>
                                    <label>Marital status</label>
                                    <select ref={this.props.createInputRef.bind(null, 'marital_status')}
                                            className="form-control">
                                        <option value="">Please select</option>
                                        <option>Married</option>
                                        <option>Partner</option>
                                        <option>Single</option>
                                        <option>Co-habiting</option>
                                        <option>Separated</option>
                                        <option>Divorced</option>
                                        <option>Civil Union</option>
                                        <option>Widowed</option>
                                    </select>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('dependants')}>
                                    <label>Number of dependants</label>
                                    <select ref={this.props.createInputRef.bind(null, 'dependants')}
                                            className="form-control">
                                        <option value="">Please select</option>
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5+</option>
                                    </select>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('dob')}>
                                    <label>Date of birth</label>
                                    <div className="form-choice">
                                        <div className="field dob-day">
                                            <input ref={this.props.createInputRef.bind(null, 'dob_day')}
                                                   type="text"
                                                   className="form-control" placeholder="DD"
                                                   maxLength="2"/>
                                        </div>
                                        <div className="field dob-month">
                                            <input ref={this.props.createInputRef.bind(null, 'dob_month')}
                                                   type="text"
                                                   className="form-control" placeholder="MM"
                                                   maxLength="2"/>
                                        </div>
                                        <div className="field dob-year">
                                            <input ref={this.props.createInputRef.bind(null, 'dob_year')}
                                                   type="text"
                                                   className="form-control" placeholder="YYYY"
                                                   maxLength="4"/>
                                        </div>
                                    </div>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('email')}>
                                    <label>Email</label>
                                    <input ref={this.props.createInputRef.bind(null, 'email')} type="text"
                                           className='form-control'/>
                                </InputWrapper>
                            </div>
                            <div className="col col-xs-12 col-md-4">
                                <InputWrapper error={this.props.getErrors('mobile_telephone')}>
                                    <label>Mobile Telephone</label>
                                    <input ref={this.props.createInputRef.bind(null, 'mobile_telephone')}
                                           type="text"
                                           className='form-control'/>
                                </InputWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const PersonalFieldset = withFieldset(Personal)

export default PersonalFieldset

